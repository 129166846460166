<template>
  <div class="left-container">
    <div class="search-box">
      <Input placeholder="输入车牌号">
        <Icon color="#000" type="md-search" slot="suffix" />
      </Input>
    </div>
    <div class="entity-status-drigger">
      <div
        @click="changeEntityType('order')"
        :class="[
          'entity-type',
          currentEntityType == 'order' ? 'current-entity-type' : '',
        ]"
      >
        工作中
      </div>
      <!-- <div
        @click="changeEntityType('driver')"
        :class="[
          'entity-type',
          currentEntityType == 'driver' ? 'current-entity-type' : '',
        ]"
      >
        司机
      </div> -->
      <div
        @click="changeEntityType('car')"
        :class="[
          'entity-type',
          currentEntityType == 'car' ? 'current-entity-type' : '',
        ]"
      >
        车辆
      </div>
      <!-- 选中下划线 -->
      <div
        :style="{
          left:
            currentEntityType == 'order'
              ? 8 + 56 + 'px'
              : 8 +56 + 112 + 'px',
        }"
        class="under-line"
      ></div>
    </div>
    <div class="entity-list-box">
      <div
        :style="{
          left: `-${contentLeftMove}px`,
        }"
        class="entity-list-inner-box"
      >
        <!-- 订单 -->
        <div class="entity-item-list-box">
          <scrollBox>
            <div class="entity-list">
              <div
                @click="checkItem('order', order)"
                v-for="order in orders"
                :key="order.id"
                :class="[
                  'entity-item',
                  current &&
                  current.type === 'order' &&
                  current.data.id === order.id
                    ? 'current-item'
                    : '',
                ]"
              >
                <div class="entity-name-row">
                  <span class="entity-name">{{ order.carName }}</span>
                  <span :class="['entity-status', 'online-class']">
                    运输中
                  </span>
                </div>
                <div class="driver-info-row">
                  <span class="driver-name">{{ order.driverName }}</span>
                  <span class="driver-phone">{{ order.driverPhone }}</span>
                  <span class="pic-box">
                    <img :src="phoneUrl" />
                  </span>
                </div>
              </div>
            </div>
          </scrollBox>
        </div>
        <!-- 司机 -->
        <div class="entity-item-list-box">
          <scrollBox>
            <div class="entity-list">
              <div
                @click="checkItem('driver', driver)"
                v-for="driver in drivers"
                :key="driver.driverUserId"
                :class="[
                  'entity-item',
                  current &&
                  current.type === 'driver' &&
                  current.data.driverUserId === driver.driverUserId
                    ? 'current-item'
                    : '',
                ]"
              >
                <div class="entity-name-row">
                  <span class="entity-name">{{ driver.driverName }}</span>
                  <span :class="['entity-status', 'offline-class']">
                    空闲中
                  </span>
                </div>
                <div class="driver-info-row">
                  <span class="driver-name">{{ driver.driverPhone }}</span>
                  <!-- <span class="driver-phone">13666666666</span> -->
                  <span class="pic-box">
                    <img :src="phoneUrl" />
                  </span>
                </div>
              </div>
            </div>
          </scrollBox>
        </div>
        <!-- 车辆 -->
        <div class="entity-item-list-box">
          <scrollBox>
            <div class="entity-list">
              <div
                @click="checkItem('car', car)"
                v-for="car in cars"
                :class="[
                  'entity-item',
                  current &&
                  current.type === 'car' &&
                  current.data.carId === car.carId
                    ? 'current-item'
                    : '',
                ]"
              >
                <div class="entity-name-row">
                  <span class="entity-name">{{ car.carPlate }}</span>
                  <span :class="['entity-status', 'offline-class']">
                    空闲中
                  </span>
                </div>
                <div class="driver-info-row">
                  <span class="driver-name">{{ car.carTelName }}</span>
                  <span class="driver-phone">{{ car.carTalPhone }}</span>
                  <span class="pic-box">
                    <img :src="phoneUrl" />
                  </span>
                </div>
              </div>
            </div>
          </scrollBox>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import phoneUrl from "@/assets/imgs/phone.png";
import scrollBox from "@/components/scrollBox";
export default {
  components: {
    scrollBox,
  },
  data() {
    return {
      phoneUrl,
      currentEntityType: "order",
      //   正在进行的订单列表
      orders: [],
      //   休息中车辆信息
      cars: [],
      //   休息中司机列表
      drivers: [],
      // 当前选中数据
      current: {
        type: "",
        data: null,
      },
    };
  },
  computed: {
    contentLeftMove() {
      return this.currentEntityType == "order"
        ? 0
        : this.currentEntityType == "driver"
        ? 240
        : 480;
    },
  },
  methods: {
    // 列表点击
    checkItem(type, data) {
      this.current = {
        type,
        data,
      };
      this.$emit("check-item", { type: type, data: { ...data } });
    },
    //   获取订单
    getWorkingTransport() {
      this.$post(this.$api.TRANSPORT.LIST, {
        transportStatus: 2,
      }).then((res) => {
        this.orders = res.list;
        this.$emit("working-cars", res.list);
      });
    },
    // 获取休息中车辆信息
    getRestCarInfo() {
      this.$post(this.$api.TRANSPORT.CAR_STATUS).then((res) => {
        if (!res || !Array.isArray(res)) {
          this.cars = [];
          return;
        }
        this.cars = res.filter(
          (item) => !item.transportList || item.transportList.length === 0
        );
        this.$emit("rest-cars", res);
      });
    },
    // 获取休息中司机信息
    getRestDriverInfo() {
      this.$post(this.$api.TRANSPORT.DRIVER_STATUS).then((res) => {
        if (!res || !Array.isArray(res)) {
          this.drivers = [];
          return;
        }
        this.drivers = res.filter(
          (item) => !item.transportList || item.transportList.length === 0
        );
      });
    },
    // 更改设备类型
    changeEntityType(type) {
      this.current = {
        type: "",
        data: null,
      };
      this.currentEntityType = type;
      this.$emit("check-item");
    },
  },
  mounted() {
    this.getWorkingTransport();
    this.getRestCarInfo();
    this.getRestDriverInfo();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
